import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  KeyboardEvent,
  UIEvent,
} from "react";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Textarea,
  Icon,
  IconButton,
  Button,
  Divider,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faClose,
  faQuestion,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsDown,
  faThumbsUp,
  faComment,
  faLightbulb,
} from "@fortawesome/free-regular-svg-icons";
import { useParams, useSearchParams } from "react-router-dom";
import useAnnotations from "../../../../../Core/Hooks/useAnnotations";
import T from "../../../../../Core/Translations";
import SessionJoinedHelper from "../../../Helpers";
import { parse } from "path";
import { CLR_PANEL_BG, CLR_SECONDARY_BG } from "../../../../../../Lib/Theme/Light/colors";
import { participantsColors } from "../../../../../Core/Hooks/useSessionJoined";
import BonfireAPI from "../../../../../Core/Data/Http";
import { SET_SPEAKER_LANGUAGE } from "../../../../../Models/Languages/urls";
import GeneralModel from "../../../../../Models/General";

const ReactionIcon = ({ reaction, fontSize }: any) => {
  switch (reaction) {
    case "👍":
      return (
        <FontAwesomeIcon
          fontSize={fontSize || "18px"}
          color={CLR_SECONDARY_BG}
          icon={faThumbsUp}
        />
      );
    case "👎":
      return (
        <FontAwesomeIcon
          fontSize={fontSize || "18px"}
          color={CLR_SECONDARY_BG}
          icon={faThumbsDown}
        />
      );
    case "💡":
      return (
        <FontAwesomeIcon
          fontSize={fontSize || "18px"}
          color={CLR_SECONDARY_BG}
          icon={faLightbulb}
        />
      );
    case "❓":
      return (
        <FontAwesomeIcon
          fontSize={fontSize || "18px"}
          color={CLR_SECONDARY_BG}
          icon={faQuestion}
        />
      );
    case "💬":
      return (
        <FontAwesomeIcon
          fontSize={fontSize || "18px"}
          color={CLR_SECONDARY_BG}
          icon={faComment}
        />
      );
    case "🔄":
      return (
        <FontAwesomeIcon
          fontSize={fontSize || "18px"}
          color={CLR_SECONDARY_BG}
          icon={faRotateRight}
        />
      );
    default:
      return null;
  }
};

const Messages = ({
  loadingMessages,
  finalSentences,
  temporarySentence,
  participantsColors,
  hideAnnotations,
  currentLanguage,
  isTeams,
  languages,
}: any) => {
  const colorsMap: any = {};
  const colorsMapRef: any = useRef({});
  var lastColor: number = 1;

  const captionsRef = useRef<HTMLDivElement>(null);
  const captionsScrollRef = useRef<HTMLDivElement>(null);
  const optionsBoxRef = useRef(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [userScrolled, setUserScrolled] = useState(false);
  const [focusedMessage, setFocusedMessage] = useState<number | null>(null);
  const [searchParams] = useSearchParams();

  const rtlLanguages = [
    "ar",
    "arc",
    "ckb",
    "dv",
    "fa",
    "ha",
    "he",
    "khw",
    "ks",
    "ku",
    "ps",
    "ur",
    "yi",
  ];

  const displayRtl = rtlLanguages.includes(currentLanguage);

  useLayoutEffect(() => {
    if (finalSentences) {
      document.addEventListener("keydown", onKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [hideAnnotations, finalSentences]);

  const onKeyDown = ({ key }: any) => {
    if (!captionsRef.current) return;
    if (hideAnnotations) return;

    switch (key) {
      case "ArrowUp":
        setFocusedMessage((prevMessage) => {
          if (captionsRef.current) {
            const newValue: number = !prevMessage
              ? captionsRef?.current?.children?.length - 1
              : prevMessage - 1;

            (
              captionsRef.current.children[newValue].children[
                captionsRef.current.children[newValue].children.length - 1
              ] as HTMLElement
            ).focus();
            startAnnotating(finalSentences[newValue].id);

            return newValue;
          }
          return prevMessage;
        });

        break;
      case "ArrowDown":
        setFocusedMessage((prevMessage) => {
          if (captionsRef.current) {
            let goDownValue = !!prevMessage ? prevMessage + 1 : 0 + 1;

            if (goDownValue > captionsRef.current.children.length - 1) {
              goDownValue = 0;
            }
            (
              captionsRef.current.children[goDownValue].children[
                captionsRef.current.children[goDownValue].children.length - 1
              ] as HTMLElement
            ).focus();
            startAnnotating(finalSentences[goDownValue].id);

            return goDownValue;
          }
          return prevMessage;
        });

        break;
      case "Escape":
        stopAnnotatingMessage();
        break;
      default:
        if (textAreaRef.current) {
          textAreaRef.current.focus();
          textAreaRef.current.setSelectionRange(
            textAreaRef.current.value.length,
            textAreaRef.current.value.length
          );
        }
        break;
    }
  };

  const { colorMode } = useColorMode();

  const { sessionId } = useParams();

  const {
    startAnnotating,
    changeMessageComment,
    changeMessageReaction,
    stopAnnotatingMessage,
    annotatingMessage,
    messageComment,
    messageReaction,
    reviseMessage,
  } = useAnnotations(finalSentences, sessionId as string, hideAnnotations);

  useEffect(() => {
    if (!userScrolled) {
      scrollToBottom(true);
    }
  }, [finalSentences, temporarySentence, userScrolled]);

  useEffect(() => {
    if (hideAnnotations) return;

    if (!messageComment.get(annotatingMessage.id) && textAreaRef.current) {
      textAreaRef.current.value = "";
    } else {
      if (textAreaRef.current) {
        textAreaRef.current.value = messageComment.get(annotatingMessage.id);
      }
    }
  }, [annotatingMessage, textAreaRef.current]);

  const scrollToBottom = (isInstant: boolean) => {
    captionsScrollRef.current?.scrollTo({
      top: captionsScrollRef.current?.scrollHeight,
      // @ts-ignore
      behavior: !isInstant ? "smooth" : "instant",
    });
  };

  const handleScrollMessages = (event: UIEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    if (scrollHeight - clientHeight - scrollTop < 1) {
      setUserScrolled(false);
      return;
    }

    if (!userScrolled) {
      setUserScrolled(true);
      return;
    }
  };
  const annotatingMessageUser =
    colorsMapRef.current[annotatingMessage?.participantName?.trim()];

  const annotatingMessageUserColor =
    participantsColors[annotatingMessageUser - 1];

  const clickOnMessage = (messageId: string, messageIndex: number) => {
    if (hideAnnotations) return;
    startAnnotating(messageId);
    setFocusedMessage(messageIndex);
  };

  const handleClickReaction = (event: any, reaction: any, sentenceId: any) => {
    event.stopPropagation();
    changeMessageReaction(reaction, sentenceId);
  };

  const annotatingInputOnKeyDown = (e: KeyboardEvent) => {
    if (hideAnnotations) return;

    if (e.key === "Enter" || e.key === "Escape") {
      stopAnnotatingMessage();
    }
  };

  if (loadingMessages) {
    return (
      <Box
        padding={5}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        bg={CLR_PANEL_BG}
        h="100vh"
      >
        <Spinner />
      </Box>
    );
  }

  const formatSentence = (sentence: string) => {
    const regex = /<explain title="([^"]*)">([^<]*)<\/explain>|([^<]+)/g;
    let match;
    const result = [];
    
    while ((match = regex.exec(sentence)) !== null) {
        if (match[1] !== undefined) {
            result.push({ sentence: match[2], label: match[1] });
        } else {
            result.push({ sentence: match[3], label: '' });
        }
    }

    const formattedResult = result.map((item: any, index: number) => (
        <span key={index}>
            <Tooltip label={item.label}>
                <span className={item.label.length !== 0 ? "tooltip" : ""}>
                    {item.sentence}
                </span>
            </Tooltip>
        </span>
    ));

    return <span>{formattedResult}</span>;
};

  const changeSpeakerLanguage = async (participantName: string, language: string) => {

    if (language === 'en') {
      language = 'en-US';
    }

    await BonfireAPI.request(SET_SPEAKER_LANGUAGE, GeneralModel, {
      language, 
      speaker: participantName,
      sessionId
    });

  }

  const renderParticipantLanguage = (participantName: string, language: string) => {

    const isModerator = searchParams.get("isModerator");

    if (!isModerator) {
      return null;
    }

    const speakingLanguages = languages.filter((lang: any) => lang.isSpeakingLanguage).map((lang: any) => lang.key);

    const languageCode = language.split("-")[0];

    return <Box p={1} backgroundColor={'green'} color={'white'}>
      {speakingLanguages.map((langCode: any) => {
        return <a 
          href={'javascript:void(0)'} 
          onClick={() => { changeSpeakerLanguage(participantName, langCode); }}
          key={langCode} 
          style={{fontWeight: languageCode === langCode ? 'bold' : 'normal', paddingRight: '5px'}}>
            {langCode}
            </a>
      })}
      
    </Box>;
  }

  return (
    <>
      <Box
        w="full"
        mt="12px"
        overflow="auto"
        h="full"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        onScroll={handleScrollMessages}
        ref={captionsScrollRef}
      >
        {isTeams && <Text color={colorMode === 'light' ? "#555" : "white"} textAlign={'right'} marginRight={2} fontSize={11}>AI generated content may be incorrect.</Text>}
        <Box
          pl="23px"
          pr="23px"
          pb="23px"
          lineHeight={6}
          borderRadius={8}
          ref={captionsRef}
          zIndex={1}
        >
          {finalSentences.map((sentence: any, index: number) => {
            var color = 1;

            if (!sentence) {
              return null;
            }
            if (sentence.participant) {
              const displaySpeakerName =
                finalSentences[index].participantName !==
                finalSentences?.[index - 1]?.participantName;

              const lastSentence =
                finalSentences[finalSentences.length - 1].message;

              const lastSentenceEndsWithPeriod =
                SessionJoinedHelper.messageEndsWithPeriod(lastSentence);

              const participantName = sentence.participantName.trim();
              if (colorsMap[participantName.trim()]) {
                color = colorsMap[participantName];
              } else {
                colorsMap[participantName] = lastColor;
                colorsMapRef.current = colorsMap;
                color = lastColor;
                lastColor += 1;
                if (lastColor == 7) {
                  lastColor = 1;
                }
              }
              return (
                <Box key={sentence.id} dir={displayRtl ? "rtl" : undefined}>
                  {displaySpeakerName && (
                    <Text
                      mt="4"
                      color={
                        colorMode === 'light' ? "#051E4D" : "white"
                      }
                      fontWeight="600"
                      fontSize="14px"
                    >
                      {sentence.participantName}
                      {renderParticipantLanguage(sentence.participantName, finalSentences[index].language)}
                    </Text>
                  )}
                  <Flex
                    tabIndex={0}
                    borderRadius={8}
                    alignItems="center"
                    onClick={() => clickOnMessage(sentence.id, index)}
                    cursor={hideAnnotations ? "default" : "pointer"}
                    position="relative"
                    _hover={hideAnnotations ? {} : { bg: "#e2e8f0" }}
                    _focus={hideAnnotations ? {} : { bg: "#e2e8f0" }}
                    outline="none"
                    role="group"
                  >
                    <Flex
                      display="none"
                      ref={optionsBoxRef}
                      _groupHover={hideAnnotations ? {} : { display: "flex" }}
                      _groupFocus={hideAnnotations ? {} : { display: "flex" }}
                      cursor="default"
                      bg="white"
                      position="absolute"
                      top="-40px"
                      right="0"
                      p="8px 16px"
                      height="fit-content"
                      borderRadius="8px"
                      border="1px solid"
                      borderColor="#D7DADD"
                      boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.16)"
                      gap="16px"
                      zIndex={10}
                    >
                      <Box
                        as="button"
                        title="Like"
                        aria-label="Like"
                        onClick={(e) =>
                          handleClickReaction(e, "👍", sentence.id)
                        }
                        bg="white"
                      >
                        <ReactionIcon reaction="👍" />
                      </Box>
                      <Box
                        as="button"
                        title="Dislike"
                        aria-label="Dislike"
                        onClick={(e) =>
                          handleClickReaction(e, "👎", sentence.id)
                        }
                        bg="white"
                      >
                        <ReactionIcon reaction="👎" />
                      </Box>
                      <Box
                        as="button"
                        title="Correct"
                        aria-label="Correct"
                        onClick={(e) =>
                          handleClickReaction(e, "💡", sentence.id)
                        }
                        bg="white"
                      >
                        <ReactionIcon reaction="💡" />
                      </Box>
                      <Box
                        as="button"
                        title="Incorrect"
                        aria-label="Incorrect"
                        onClick={(e) =>
                          handleClickReaction(e, "❓", sentence.id)
                        }
                        bg="white"
                      >
                        <ReactionIcon reaction="❓" />
                      </Box>
                      <Box
                        as="button"
                        title="Start annotation"
                        aria-label="Start annotation"
                        bg="white"
                        onClick={() => startAnnotating(sentence.id)}
                      >
                        <ReactionIcon reaction="💬" />
                      </Box>
                      {/*!sentence.isRevised && (
                        <>
                          <Divider orientation="vertical" h="24px" />

                          <Box
                            as="button"
                            bg="white"
                            onClick={() => reviseMessage(sentence.id)}
                          >
                            <ReactionIcon reaction="🔄" />
                          </Box>
                        </>
                      )*/}
                    </Flex>
                    <Box
                      fontWeight="400"
                      fontSize="14px"
                      boxDecorationBreak="clone"
                      borderRadius="4px"
                      padding="6px"
                      mt="1"
                      mb="4px"
                      w="fit-content"
                      lineHeight="2"
                      backgroundColor={participantsColors[color - 1]}
                      position="relative"
                      border="2px solid transparent"
                      _groupHover={
                        hideAnnotations ? {} : { borderColor: "primaryBlue" }
                      }
                      _groupFocus={
                        hideAnnotations ? {} : { borderColor: "primaryBlue" }
                      }
                    >
                      <Text color='#000'>
                        {formatSentence(sentence.message.trim())}{`${
                            (index === finalSentences.length - 1 ||
                              finalSentences.length === 0) &&
                            temporarySentence &&
                            !lastSentenceEndsWithPeriod
                              ? ` ${temporarySentence?.message}`
                              : ""
                          }`}
                      </Text>
                    </Box>
                    <Flex
                      position="absolute"
                      display={hideAnnotations ? "none" : "flex"}
                      left="6px"
                      bottom="-2px"
                      zIndex={1}
                      gap="6px"
                    >
                      {messageComment.get(sentence.id) && (
                        <Flex
                          border="1px solid"
                          borderColor="#D7DADD"
                          as="button"
                          onClick={() => startAnnotating(sentence.id)}
                          p="2px"
                          textAlign="center"
                          pl="5px"
                          pr="5px"
                          borderRadius="40px"
                          bg="white"
                        >
                          <ReactionIcon fontSize="12px" reaction="💬" />
                        </Flex>
                      )}
                      {sentence.isRevised && (
                        <Flex
                          border="1px solid"
                          borderColor="#D7DADD"
                          p="2px"
                          textAlign="center"
                          pl="5px"
                          pr="5px"
                          borderRadius="40px"
                          bg="white"
                        >
                          <ReactionIcon fontSize="12px" reaction="🔄" />
                        </Flex>
                      )}
                      {Array.isArray(messageReaction.get(sentence.id)) &&
                        messageReaction
                          .get(sentence.id)
                          .map((reaction: string) => (
                            <Flex
                              key={reaction}
                              border="1px solid"
                              borderColor="#D7DADD"
                              p="2px"
                              h="fit-content"
                              textAlign="center"
                              pl="5px"
                              pr="5px"
                              borderRadius="40px"
                              bg="white"
                            >
                              <ReactionIcon
                                fontSize="12px"
                                reaction={reaction}
                              />
                            </Flex>
                          ))}
                    </Flex>
                  </Flex>
                  {temporarySentence &&
                    index === finalSentences.length - 1 &&
                    lastSentenceEndsWithPeriod && (
                      <>
                        <Box
                          fontWeight="400"
                          fontSize="14px"
                          boxDecorationBreak="clone"
                          borderRadius="4px"
                          padding="6px"
                          mt="1"
                          mb="4px"
                          w="fit-content"
                          lineHeight="2"
                          border="2px solid transparent"
                          backgroundColor={participantsColors[color - 1]}
                        >
                          <Text color='#000'>
                            {temporarySentence?.message}
                          </Text>
                        </Box>
                      </>
                    )}
                </Box>
              );
            }

            return (
              <span style={{ display: "block", margin: 2 }}>
                <span>{formatSentence(sentence.message.trim())}</span>
              </span>
            );
          })}
        </Box>
      </Box>
      <Box position="relative">
        {userScrolled && (
          <Box position="absolute" top="-40px" right="calc(50% - 18px)">
            <Flex
              as="button"
              opacity={0.7}
              bg="#fff"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              w="30px"
              height="30px"
              fontSize="18px"
              onClick={() => {
                scrollToBottom(false);
              }}
              zIndex={10}
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </Flex>
          </Box>
        )}
      </Box>
      {annotatingMessage && (
        <Box
          bg={colorMode === 'light' ? 'white' : '#cccccc33'}
          h="fit-content"
          borderTopRadius="0px"
          border="1px solid #1E599E"
          boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.16)"
          position="sticky"
          bottom="0px"
          borderRadius="8px"
          mt="6px"
          zIndex={10}
        >
          <Flex
            w="full"
            justifyContent="space-between"
            fontWeight="400"
            borderTopRadius="8px"
            pl="23px"
            pr="23px"
            pt="23px"
            alignItems="center"
          >
            <Box w="full">
              <Flex justifyContent="space-between" alignItems="center">
                <Text noOfLines={1} variant="beTextDescription">
                  {annotatingMessage.participantName}
                </Text>
                <Button aria-label="close" onClick={stopAnnotatingMessage}>
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </Flex>

              <Text
                fontWeight="400"
                fontSize="14px"
                mt="12px"
                boxDecorationBreak="clone"
                borderRadius="4px"
                padding="4px 10px"
                w="fit-content"
                lineHeight="2"
                background={annotatingMessageUserColor}
                noOfLines={1}
              >
                {annotatingMessage.message}
              </Text>
            </Box>
          </Flex>
          <Divider mt="16px" mb="16px" borderColor="#D7DFE8" />
          <Flex pl="23px" pr="23px" pb="16px">
            <Textarea
              ref={textAreaRef}
              _placeholder={{
                fontSize: "14px",
                fontWeight: "600",
              }}
              defaultValue={messageComment.get(annotatingMessage.id) || ""}
              onKeyDown={annotatingInputOnKeyDown}
              onChange={(e) => changeMessageComment(e.target.value)}
              variant="unstyled"
              placeholder={`Add your annotation here`}
              resize="none"
              bg="white"
              width="100%"
              rows={!!messageComment.get(annotatingMessage.id) ? 6 : 1}
            />
            <Box textAlign="right" mt="auto">
              <Button
                variant="unstyled"
                color="primaryBlue"
                fontSize="14px"
                onClick={stopAnnotatingMessage}
              >
                {T.get("Save")}
              </Button>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Messages;
